/* Add boxes around each button */

.navbutton {
  border: 2px solid rgb(red, green, blue);
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  font-family: Quicksand;
  width: 8.8rem;
}

.navbutton-resume {
  border: 2px solid #64ffda;
  border-radius: 50;
  border-collapse: separate;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  font-family: Quicksand;
  width: 8.8rem;
  overflow: hidden;
  perspective: 1px;
  transition: background-color 400ms ease-in-out;
}

.navbutton-resume:hover {
  background-color: #64ffdb11;
}

.navbar-color-1 {
  background-color: #0a192f;
  margin-top: 15px;
}

.pageName {
  margin-left: 1rem;
  padding-top: 0rem;
  font-size: 3rem;
}

a.nav-link {
  color: #8892b0;
  transition: color 400ms ease-in-out;
}

a:hover {
  color: #64ffda !important;
}

/* hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1rem;
  padding: 0;
  color: #233554;
} */
