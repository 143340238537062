.title {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.a {
  margin-bottom: 0rem;
  font-family: Quicksand;
}

@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

body {
  background: rgb(19, 19, 19);
  color: rgb(0, 0, 0);
  font-family: 'Noto Sans', sans-serif;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #0a192f;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1.02);
  overflow: hidden;
}
