.title {
  margin-left: 1rem;
}

td {
  border: 1px solid #999;
  padding: 0.5rem;
}

.table-hover tbody tr:hover td {
  color: #8892b0;
}

th {
  border: 1px solid #999;
  padding: 0.5rem;
  color: #a8b2d1;
}

table {
  margin-top: 0.5rem;
}

.git-icon {
  height: 2.5rem;
}

/* row-wide links */
.table-hover tbody tr td {
  cursor: 'pointer';
}

.table-hover tbody tr:hover td {
  cursor: 'pointer';
}

/* links */
.table-hover tbody tr td a {
  display: inline-block;
  color: #64ffda;
  margin: 0;
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a {
  color: #64ffda;
  text-decoration: none;
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a:hover {
  color: #64ffda;
  text-decoration: none;
  cursor: 'pointer';
}

.table-hover tbody tr:hover td a:after {
  display: block;
  content: '';
  border-bottom: solid 1px #64ffda;
  transform: scaleX(0);
  transition: transform 125ms ease-in-out;
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a:hover:after {
  color: #64ffda;
  transform: scaleX(1);
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a.fromLeft:after {
  color: inherit;
  transform-origin: 0% 50%;
  cursor: 'pointer';
}

/* hide cols for mobile view */
@media screen and (max-width: 800px) {
  .table-hover thead tr th:nth-child(2),
  .table-hover thead tr th:nth-child(4),
  .table-hover thead tr th:nth-child(5) {
    display: none;
  }

  .table-hover tbody tr td:nth-child(2),
  .table-hover tbody tr td:nth-child(4),
  .table-hover tbody tr td:nth-child(5) {
    display: none;
  }
}
