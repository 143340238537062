.basic-grid {
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  /* margin-bottom: 1rem; */
  /* 1 too skinny, too much code */
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  /* 2 cleaner code */
  /* grid-template-columns: repeat(12, 1fr); */
  /* 3  better sizing, but overflows */
  /* grid-template-columns: repeat(12, minmax(240px, 1fr)); */
  /* 4 final */
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: 240px;
}

.card-tall {
  grid-row: span 2 / auto;
}

/* Ensure the gallery stays a certain width when the screen is large */

/* @media screen and (min-width: 1100px) {
  .basic-grid {
    width: 1100px;
  }
} */

/* Small screens */

@media screen and (min-width: 800px) {
  .card-wide {
    grid-column: span 2 / auto;
  }
  .basic-grid {
    /* height: 80vh; */
    overflow: auto;
  }
}

.insta-icon {
  height: 2rem;
}
