.title {
  margin-left: 1rem;
}

.text {
  margin-top: 2rem;
  color: #8892b0;
  margin-left: 1rem;
  font-family: Quicksand;
  font-size: 1.1rem;
}
