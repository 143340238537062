.text {
  text-decoration: none;
  font-size: 1.1rem;
  color: #8892b0;
  padding-right: 1rem;
}

.a {
  text-decoration: none;
  color: inherit;
}

.bottom-padding {
  padding-bottom: 4rem;
}
