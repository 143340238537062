@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url(/static/media/Quicksand-VariableFont_wght.7dd2a729.ttf) format('truetype');
}

/* Add boxes around each button */

.navbutton {
  border: 2px solid rgb(red, green, blue);
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  font-family: Quicksand;
  width: 8.8rem;
}

.navbutton-resume {
  border: 2px solid #64ffda;
  border-radius: 50;
  border-collapse: separate;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  font-family: Quicksand;
  width: 8.8rem;
  overflow: hidden;
  -webkit-perspective: 1px;
          perspective: 1px;
  transition: background-color 400ms ease-in-out;
}

.navbutton-resume:hover {
  background-color: #64ffdb11;
}

.navbar-color-1 {
  background-color: #0a192f;
  margin-top: 15px;
}

.pageName {
  margin-left: 1rem;
  padding-top: 0rem;
  font-size: 3rem;
}

a.nav-link {
  color: #8892b0;
  transition: color 400ms ease-in-out;
}

a:hover {
  color: #64ffda !important;
}

/* hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1rem;
  padding: 0;
  color: #233554;
} */

/* Global styles go in here. Page specific styles should be placed in the relative css file. */
a[href^='mailto:'] {
  color: #64ffda;
  text-decoration: none;
}
a[href^='mailto:']:hover {
  color: #64ffda;
  text-decoration: none;
}
a {
  color: #64ffda;
  text-decoration: none;
}
a:hover {
  color: #64ffda;
  text-decoration: none;
}

p {
  display: inline-block;
  color: #8892b0;
}

/* links within p */
p a {
  display: inline-block;
  color: #64ffda;
  margin: 0;
}
p a:after {
  display: block;
  content: '';
  border-bottom: solid 1px #64ffda;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out, -webkit-transform 125ms ease-in-out;
}
p a:hover:after {
  color: #64ffda;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
p a.fromLeft:after {
  color: inherit;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}

h1 {
  font-family: Quicksand;
  display: inline-block;
  color: #a8b2d1;
}

h2 {
  font-family: Quicksand;
  display: inline-block;
  color: #a8b2d1;
}

h3 {
  font-family: Quicksand;
  display: inline-block;
  color: #a8b2d1;
}

/* links within h3 */
h3 a {
  display: inline-block;
  color: #64ffda;
  margin: 0;
}
h3 a:after {
  display: block;
  content: '';
  border-bottom: solid 1px #64ffda;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out, -webkit-transform 125ms ease-in-out;
}
h3 a:hover:after {
  color: #64ffda;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
h3 a.fromLeft:after {
  color: inherit;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}
h3 a[href^='mailto:'] {
  color: #64ffda;
  text-decoration: none;
}
h3 a[href^='mailto:']:hover {
  color: #64ffda;
  text-decoration: none;
}
h3 a {
  color: #64ffda;
  text-decoration: none;
}
h3 a:hover {
  color: #64ffda;
  text-decoration: none;
}

.text {
  display: inline-block;
  font-family: Quicksand;
  color: #8892b0;
}

/* links within lists */
li a {
  display: inline-block;
  color: #64ffda;
  margin: 0;
}
li a:after {
  display: block;
  content: '';
  border-bottom: solid 1px #64ffda;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out, -webkit-transform 125ms ease-in-out;
}
li a:hover:after {
  color: #64ffda;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
li a.fromLeft:after {
  color: inherit;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}
li a[href^='mailto:'] {
  color: #64ffda;
  text-decoration: none;
}
li a[href^='mailto:']:hover {
  color: #64ffda;
  text-decoration: none;
}
li a {
  color: #64ffda;
  text-decoration: none;
}
li a:hover {
  color: #64ffda;
  text-decoration: none;
}

.text {
  text-decoration: none;
  font-size: 1.1rem;
  color: #8892b0;
  padding-right: 1rem;
}

.a {
  text-decoration: none;
  color: inherit;
}

.bottom-padding {
  padding-bottom: 4rem;
}

.title {
  margin-left: 1rem;
}

td {
  border: 1px solid #999;
  padding: 0.5rem;
}

.table-hover tbody tr:hover td {
  color: #8892b0;
}

th {
  border: 1px solid #999;
  padding: 0.5rem;
  color: #a8b2d1;
}

table {
  margin-top: 0.5rem;
}

.git-icon {
  height: 2.5rem;
}

/* row-wide links */
.table-hover tbody tr td {
  cursor: 'pointer';
}

.table-hover tbody tr:hover td {
  cursor: 'pointer';
}

/* links */
.table-hover tbody tr td a {
  display: inline-block;
  color: #64ffda;
  margin: 0;
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a {
  color: #64ffda;
  text-decoration: none;
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a:hover {
  color: #64ffda;
  text-decoration: none;
  cursor: 'pointer';
}

.table-hover tbody tr:hover td a:after {
  display: block;
  content: '';
  border-bottom: solid 1px #64ffda;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out, -webkit-transform 125ms ease-in-out;
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a:hover:after {
  color: #64ffda;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  cursor: 'pointer';
}
.table-hover tbody tr:hover td a.fromLeft:after {
  color: inherit;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  cursor: 'pointer';
}

/* hide cols for mobile view */
@media screen and (max-width: 800px) {
  .table-hover thead tr th:nth-child(2),
  .table-hover thead tr th:nth-child(4),
  .table-hover thead tr th:nth-child(5) {
    display: none;
  }

  .table-hover tbody tr td:nth-child(2),
  .table-hover tbody tr td:nth-child(4),
  .table-hover tbody tr td:nth-child(5) {
    display: none;
  }
}

.title {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.a {
  margin-bottom: 0rem;
  font-family: Quicksand;
}

body {
  background: rgb(19, 19, 19);
  color: rgb(0, 0, 0);
  font-family: 'Noto Sans', sans-serif;
}

.card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #0a192f;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  -webkit-transform: translateY(-3px) scale(1.02);
          transform: translateY(-3px) scale(1.02);
  overflow: hidden;
}

.basic-grid {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  /* margin-bottom: 1rem; */
  /* 1 too skinny, too much code */
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  /* 2 cleaner code */
  /* grid-template-columns: repeat(12, 1fr); */
  /* 3  better sizing, but overflows */
  /* grid-template-columns: repeat(12, minmax(240px, 1fr)); */
  /* 4 final */
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: 240px;
}

.card-tall {
  grid-row: span 2 / auto;
}

/* Ensure the gallery stays a certain width when the screen is large */

/* @media screen and (min-width: 1100px) {
  .basic-grid {
    width: 1100px;
  }
} */

/* Small screens */

@media screen and (min-width: 800px) {
  .card-wide {
    grid-column: span 2 / auto;
  }
  .basic-grid {
    /* height: 80vh; */
    overflow: auto;
  }
}

.insta-icon {
  height: 2rem;
}

.lightbox {
  /* Overlay entire screen */
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* A bit of padding around image */
  padding: 1em;

  /* Translucent background */
  background: rgba(0, 0, 0, 0.8);
}

/* Unhide the lightbox when it's the target */
.lightbox:target {
  display: block;
}

.lightbox span {
  /* Full width and height */
  display: block;
  width: 100%;
  height: 100%;

  /* Size and position background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.title {
  margin-left: 1rem;
}

.text {
  margin-top: 2rem;
  margin-left: 1rem;
  font-family: Quicksand;
  font-size: 1.1rem;
}

.title {
  margin-left: 1rem;
}

.text {
  margin-top: 2rem;
  color: #8892b0;
  margin-left: 1rem;
  font-family: Quicksand;
  font-size: 1.1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

